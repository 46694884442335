/* .wishtok_whatsapp_container {
    display: flex;
    height: calc(100vh - 70px);
} */

.wishtok_whatsapp_chat-list {
    width: 25%;
    background: #f1f1f1;
    overflow-y: auto;
    padding: 0px;
}

.wishtok_whatsapp_chat-item {
    display: flex;
    flex-direction: column;
    border-bottom: 0.5px solid #e0e0e0;
    padding: 10px;
    background: #fff;
    cursor: pointer;
    transition: background 0.3s;
}

.wishtok_whatsapp_chat-item:hover {
    background: #eee;
}

.wishtok_whatsapp_chat-name {
    font-weight: bold;
}

.wishtok_whatsapp_chat-message {
    color: #999;
    flex-grow: 1;
    margin: 0px;
}

/* .wishtok_whatsapp_chat-time {
    text-align: right;
    font-size: 0.8em;
    color: #999;
} */

/* .wishtok_whatsapp_chat-unread {
    background: #1AC152;
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    min-height: 20px;
    min-width: 20px;
    aspect-ratio: 1 / 1;
} */


.wishtok_whatsapp_chat-container {
    width: 50%;
}

.wishtok_whatsapp_chat-window {
    display: flex;
    flex-direction: column;
    background: url('./whatsapp.jpg') center center no-repeat;
    background-size: cover;
    height: 100%;
    /* overflow-y: auto; */
}

.wishtok_whatsapp_chat-header {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #8BCBB4;
    color: white;
    border-bottom: 1px solid #ccc;
}

.wishtok_whatsapp_chat-title {
    flex: 1;
    margin-left: 10px;
    font-size: 20px;
}

/* .wishtok_whatsapp_search {
    display: flex;
    align-items: center;
    background: white;
    color: #737791;
    padding: 5px;
    border-radius: 20px;
}

.wishtok_whatsapp_search input {
    border: none;
    margin-left: 5px;
    outline: none !important;
} */

.wishtok_whatsapp_message-list {
    flex: 1;
    padding: 20px;
    /* padding-left: 20px;
    padding-right: 20px; */
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background: rgba(255, 255, 255, 0.8);
}

.wishtok_whatsapp_message-item {
    display: flex;
    margin-bottom: 10px;
    max-width: 70%;
}

.wishtok_whatsapp_message-self {
    padding-left: 10px;
    padding-right: 10px;
    align-self: flex-start;
    background: #dcf8c6;
    border-radius: 10px 10px 0 10px;
}

.wishtok_whatsapp_message-other {
    padding-left: 10px;
    padding-right: 10px;
    align-self: flex-start;
    background: #fff;
    border-radius: 10px 10px 10px 0;
}

.wishtok_whatsapp_message-text {
    padding-top: 10px;
    min-width: 80px;
    max-width: 300px;
    border-radius: 10px;
}

.wishtok_whatsapp_message-time {
    font-size: 0.8em;
    min-width:45px;
    color: #999;
    text-align: right;
    align-self: end;
    }
    
    .active-class {
        background-color: #d4d2d2;
    }
    
    .date {
        color: #54656f;
        border-radius: 9px;
        width: 100px;
        height: 25px;
        background-color: #e5e5e5f2;
    }


.wishtok_whatsapp_message-inputField {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none !important;
}

/* ============== */

.wishtok_whatsapp_action {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.wishtok_whatsapp_send {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.wishtok_whatsapp_action .wishtok_whatsapp_dropup {
    position: absolute;
    bottom: 50px;
    left: 0px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    z-index: 1000;
    padding: 10px;
}

.wishtok_whatsapp_dropup-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background 0.3s;
}

.wishtok_whatsapp_dropup-item:hover {
    background: #f1f1f1;
}

.wishtok_whatsapp_dropup-item span {
    margin-left: 10px;
}

.wishtok_whatsapp_plus-icon {
    cursor: pointer;
    color: #fff;
    background-color: #737791;
    padding: 5px;
    border-radius: 50%;
}

.wishtok_whatsapp_send-icon {
    cursor: pointer;
    color: #fff;
    background-color: #737791;
    padding: 5px;
    border-radius: 50%;
}

.wishtok_whatsapp_message-input {
    display: flex;
    padding: 10px;
    background: #eee;
    border-top: 1px solid #ccc;
    outline: none !important;
}


.wishtok_whatsapp_chat-window-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.wishtok_whatsapp_chat-window {
    max-width: 600px;
    width: 100%;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background: url('./whatsapp.jpg') center center no-repeat;
}